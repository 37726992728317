import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { listSteps } from '@wix/ambassador-challenges-v1-challenge/http';
import { querySteps } from '@wix/ambassador-online-programs-v3-step/http';

import {
  DescriptionFieldSet,
  DurationUnit,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { isV3enabled } from '../../../../experiments/isV3enabled';
import type { Step } from '../../../../types/v3Types';

export default async function requestChallengeSteps(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<Step[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  if (isV3enabled(flowAPI)) {
    return (
      await request(
        flowAPI,
        querySteps({
          query: {
            filter: {
              programId: slugOrChallengeId,
            },
          },
        }),
      )
    )?.data?.steps;
  }
  return (
    await request(
      flowAPI,
      listSteps({
        challengeId: slugOrChallengeId,
        fromOffset: {
          unit: DurationUnit.DAYS,
          value: 0,
        },
        toOffset: {
          unit: DurationUnit.DAYS,
          value: 1000,
        },
        descriptionFieldSet: DescriptionFieldSet.STANDARD,
      }),
    )
  )?.data?.steps;
}
