import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { ISettingsEvents, RolesState } from './Widget/settingsEvents';
import { ButtonState } from '../ChallengesList/Widget/settingsEvents';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { LayoutSelectedTab } from './Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';
import { locationProviderPropsMap } from '../../contexts/Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { participantSectionsPropsMap } from '../../contexts/ParticipantSections/ParticipantSectionsPropsMap';
import { resolveStepDataProviderPropsMap } from '../../contexts/ResolveStep/ResolveStepDataProviderPropsMap';
import { socialGroupsPropsMap } from '../../contexts/SocialGroups/SocialGroupsPropsMap';
import {
  getChallengeInitialData,
  resetChallengeCache,
} from '../../contexts/storage-contexts/Challenge';
import { getBadgesInitialData } from '../../contexts/storage-contexts/Badges';
import { getPaidPlansInitialData } from '../../contexts/storage-contexts/PaidPlans';
import { Referrer } from '../../contexts/storage/referrer';
import {
  getProgramSlug,
  isParticipantPageAvailable,
} from '../../contexts/Location/helpers/generateLinksToProgramForList';
import { PARTICIPANT_PAGE_ID } from '../../editor/app-config';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { isUserJoinedAlready } from '../../contexts/User/helpers/userTypeHandlers';
import { ISidebarMobileScreenFromSettings } from './Settings.mobile/tabs/Design/interface';
import { getVideoInitialData } from '../../contexts/storage-contexts/Video';

export const initChallengePage = async (flowAPI: ControllerFlowAPI) => {
  const { setProps } = flowAPI.controllerConfig;

  try {
    const initialProps = {
      ...(await arrayToObjectAsync([
        getBaseProvidersData({
          flowAPI,
        }),
        getChallengeInitialData(flowAPI, Referrer.CHALLENGES_PAGE),
      ])),
      ...(await arrayToObjectAsync([
        getBadgesInitialData(),
        getPaidPlansInitialData(flowAPI),
        resolveStepDataProviderPropsMap(flowAPI),
        participantSectionsPropsMap(flowAPI),
        socialGroupsPropsMap(flowAPI),
        userProviderPropsMap(flowAPI),
        getVideoInitialData(flowAPI),
      ])),
    };

    const slug = getProgramSlug(initialProps?.challengeData?.challenge);

    renderSeoMetatagsPage(flowAPI, initialProps?.challengeData?.challenge);

    (flowAPI.bi as any).updateDefaults({
      origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
      screenName: isUserJoinedAlready(initialProps.userType)
        ? ScreenNames.ChallengePageForParticipant
        : ScreenNames.ChallengePage,
      challengeId: initialProps.challengeData?.challenge?.id,
    });

    // If participant of the program goes to the visitor page, he should be redirected.
    if (
      (isUserJoinedAlready(initialProps.userType) ||
        isForcedPreviewParticipant(initialProps.query)) &&
      (await isParticipantPageAvailable(flowAPI)) &&
      flowAPI.environment.isViewer
    ) {
      setProps({
        isForcedBlankPage: true,
      });
      initialProps.goToPage({
        pageId: PARTICIPANT_PAGE_ID,
        challengeId: slug,
      });
    } else {
      setProps({ ...initialProps });
    }
  } catch (err) {
    console.error(err);
  }
};

export const clearProvidersCache = () => {
  resetChallengeCache();
  locationProviderPropsMap?.cache?.clear();
  userProviderPropsMap?.cache?.clear();
  participantSectionsPropsMap?.cache?.clear();
};

export const softClearProvidersCache = () => {
  participantSectionsPropsMap?.cache?.clear();
};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);

  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on('LayoutSelectedTab', (value) => {
    setProps({
      shownTab: value,
    });
  });

  settingsEventsHandler.on('sidebarLayoutMobileScreen', (value) => {
    const newProps: any = {};

    newProps.sidebarLayoutMobileScreen = value;

    if (value === ISidebarMobileScreenFromSettings.Overview) {
      newProps.shownTab = LayoutSelectedTab.Overview;
    } else if (value === ISidebarMobileScreenFromSettings.Schedule) {
      newProps.shownTab = LayoutSelectedTab.Schedule;
    }

    setProps(newProps);
  });

  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      pageRole: RolesState.Visitor,
      sidebarLayoutMobileScreen: null,
    });
  });

  return {
    async pageReady() {
      await initChallengePage(flowAPI);

      flowAPI.controllerConfig.wixCodeApi?.location?.onChange(async () => {
        clearProvidersCache();
        await initChallengePage(flowAPI);
      });
    },
    updateConfig($w, config) {
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
